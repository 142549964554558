import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Button } from 'storybook-design-system-drapo';
import { InstallerContext } from '@auth/utils/authContext';
import {
  OperationsListContext,
  SimulatorContext,
} from '@simulator/utils/simulatorContext';
import { IOperationDatasType } from '@simulator/utils/simulatorTypes';
import { useLocation } from 'react-router-dom';
import OperationCardSummary from './OperationCardSummary';

interface OperationListSummaryProps {
  updatedOperationsSelected: IOperationDatasType[];
  setOperationSelected: Dispatch<SetStateAction<IOperationDatasType[]>>;
  mprIsSelect: boolean;
  ceeIsSelect: boolean;
}

function OperationListSummary({
  updatedOperationsSelected,
  setOperationSelected,
  mprIsSelect,
  ceeIsSelect,
}: OperationListSummaryProps): JSX.Element {
  const { operationList } = useContext(OperationsListContext);
  const { colorPrimary, radiusPx, colorTextButton, isThemeDark } =
    useContext(InstallerContext);
  const [numberOfOperations, setNumberOfOperations] = useState<number>(
    operationList.length
  );

  const { updateStepNumber } = useContext(SimulatorContext);

  const onSelect = (choice: boolean, operation: IOperationDatasType) => {
    if (choice) {
      setOperationSelected((oldOperationList) => [
        ...oldOperationList,
        operation,
      ]);
    } else {
      const isSameId = (element: IOperationDatasType) =>
        element.id === operation.id;
      setOperationSelected((oldOperationList) => {
        const newOperationList = [...oldOperationList];
        const index: number = newOperationList.findIndex(isSameId);
        newOperationList.splice(index, 1);
        return newOperationList;
      });
    }
  };

  const addOperation = () => {
    updateStepNumber(1);
  };
  const location = useLocation();
  /* eslint-disable react/no-array-index-key */
  return (
    <div
      style={{
        borderRadius: radiusPx,
        border:
          location.search.includes('?integration=iframe') && !isThemeDark
            ? '1px solid #d2d2d2'
            : '',
      }}
      className="w-full sb-text-rem-body2 py-[1.5rem]  px-[1.5rem] dark:bg-dark-1 bg-white dark:text-white text-colorTextLightMode"
    >
      <p className="mb-[1.5rem] pt-[.5rem]">Détails des opérations</p>
      {operationList.map((operation, index) => {
        const updatedOperation =
          updatedOperationsSelected.find((op) => op.id === operation.id) ||
          operation;

        const isSelected = updatedOperationsSelected.some(
          (updatedOp) => updatedOp.id === operation.id
        );

        return (
          <OperationCardSummary
            key={`${operation.id}-card-${index}`}
            operation={updatedOperation}
            index={index}
            onSelect={onSelect}
            isSelected={isSelected}
            numberOfOperations={numberOfOperations}
            setNumberOfOperations={setNumberOfOperations}
            mprIsSelect={mprIsSelect}
            ceeIsSelect={ceeIsSelect}
          />
        );
      })}
      <Button
        borderRadius={radiusPx}
        addClass="mx-auto lg:mx-0 lg:mb-0 mb-[1rem] !text-[.75rem] !w-full"
        label="Ajouter une opération"
        arrow
        color={colorTextButton}
        backgroundColor={colorPrimary}
        onClick={() => addOperation()}
        dataTestId="add-operation"
      />
    </div>
  );
}

export default OperationListSummary;
