import { InstallerContext } from '@auth/utils/authContext';
import { useContext } from 'react';
import { WarningIcon } from '@assets/svgComponents/svgComponents';
import { red3, backgroundBodyLightMode } from '@assets/colors';

type ModalBarTh125Types = {
  operationName: string;
  operationCode: string;
  relatedOperations: { code: string; name: string }[];
};
function ModalEligibilityVMC({
  operationName,
  operationCode,
  relatedOperations,
}: ModalBarTh125Types): JSX.Element {
  const { radiusPx } = useContext(InstallerContext);

  const radius = radiusPx === '0px' ? '0px' : '8px';

  return (
    <div
      style={{ borderRadius: radius, backgroundColor: red3 }}
      className="text-[.875rem] leading-[1.5rem] p-[2rem] flex flex-col space-y-[1rem] mb-5"
    >
      <WarningIcon fill="white" width="1.5rem" height="1.5rem" />
      <div className="w-full text-white flex justify-between items-center">
        <div className="flex items-center space-x-[1rem]">
          <p className="font-[500]">
            Pour bénéficier de la prime MPR liée à l&apos;opération{' '}
            {operationCode} ({operationName}), celle-ci doit être associée à au
            moins une des opérations suivantes :
          </p>
        </div>
      </div>
      <div style={{ color: backgroundBodyLightMode }}>
        <ul>
          {relatedOperations.map((op) => (
            <li>
              - {op.code.toUpperCase()} ({op.name})
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ModalEligibilityVMC;
