/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-nested-ternary */
import {
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
} from 'react';
import { ReactComponent as Checkbox } from '@assets/images/checkbox.svg';
import { ReactComponent as NotChecked } from '@assets/images/not_checked_white.svg';
import { InstallerContext } from '@auth/utils/authContext';
import { numFormatSpace, convertHexToRGBA } from '@utils/utils';
import { IOperationDatasType } from '@simulator/utils/simulatorTypes';
import { BonusCard } from '@simulator/components/summary/BonusCard';
import { colorTextLightMode, dark3, dark4, white } from '@assets/colors';
import { EditSquareIcon } from '@assets/svgComponents/svgComponents';
import { SimulatorContext } from '@simulator/utils/simulatorContext';

interface OperationCardSummaryProps {
  operation: IOperationDatasType;
  index: number;
  onSelect: (choice: boolean, operation: IOperationDatasType) => void;
  isSelected: boolean;
  numberOfOperations: number;
  setNumberOfOperations: Dispatch<SetStateAction<number>>;
  mprIsSelect: boolean;
  ceeIsSelect: boolean;
}
function OperationCardSummary({
  operation,
  index,
  onSelect,
  isSelected,
  numberOfOperations,
  setNumberOfOperations,
  mprIsSelect,
  ceeIsSelect,
}: OperationCardSummaryProps): JSX.Element {
  const { installer, radiusPx, isThemeDark, colorPrimary, colorTextButton } =
    useContext(InstallerContext);
  const {
    updateCurrentOperation,
    updateStepNumber,
    updateMultipleOperations,
    updateOperationInEdit,
  } = useContext(SimulatorContext);
  const [choiceSelected, setChoiceSelected] = useState<boolean>(true);

  const onClickChoice = () => {
    let number = numberOfOperations;
    if (number > 1) {
      if (!choiceSelected) {
        number += 1;
      } else {
        number -= 1;
      }
      onSelect(!choiceSelected, operation);
      setChoiceSelected(!choiceSelected);
    } else {
      if (!choiceSelected) {
        number += 1;
        onSelect(!choiceSelected, operation);
      }
      setChoiceSelected(true);
    }
    setNumberOfOperations(number);
  };

  const cee =
    operation.primes.cdp === 0
      ? `${numFormatSpace(operation.primes.cee)} € TTC`
      : `${numFormatSpace(operation.primes.cdp)} € TTC`;

  const ceeBenef = () => {
    if (!installer.eligibility?.includes('CEE')) {
      if (operation.primes.cee_beneficiary) {
        return ` (${operation.primes.cee_beneficiary})`;
      }
      return 'partenaire';
    }
    return '';
  };

  const remains = () => {
    const costMaterial = parseFloat(operation.totalCost);
    const primeMPR = mprIsSelect ? operation.primes.mpr : 0;
    const primeCEEToUse =
      operation.primes.cdp === 0 ? operation.primes.cee : operation.primes.cdp;
    const primeCEE = ceeIsSelect ? primeCEEToUse : 0;
    const total = costMaterial - primeMPR - primeCEE;

    return `${
      total > 0 ? numFormatSpace(costMaterial - primeMPR - primeCEE) : 0
    } € TTC`;
  };

  const totalBonus = () => {
    let total = 0;
    if (
      operation.primes.bonus &&
      Object.keys(operation.primes.bonus).length > 0
    ) {
      for (let i = 0; i < Object.keys(operation.primes.bonus).length; i += 1) {
        if (Object.keys(operation.primes.bonus)[i] !== 'partner') {
          total += operation.primes.bonus[
            Object.keys(operation.primes.bonus)[i]
          ] as number;
        }
      }
    }
    return total;
  };

  const textColor = isThemeDark
    ? white
    : choiceSelected
    ? colorTextButton
    : colorTextLightMode;

  const editOperation = () => {
    updateCurrentOperation(operation);
    updateStepNumber(3);
    updateMultipleOperations([operation]);
    updateOperationInEdit(true);
  };

  useEffect(() => {
    setChoiceSelected(isSelected);
  }, [isSelected]);

  return (
    <>
      <div
        tabIndex={index}
        role="button"
        onKeyDown={undefined}
        onClick={onClickChoice}
        style={{
          borderRadius: radiusPx,
          color: textColor,
          backgroundColor:
            choiceSelected && isThemeDark
              ? dark4
              : choiceSelected && !isThemeDark
              ? colorPrimary
              : !choiceSelected && isThemeDark
              ? dark3
              : !choiceSelected && !isThemeDark
              ? convertHexToRGBA(colorPrimary, 0.1)
              : '',
        }}
        className="w-full pt-[1.5rem] px-[1.5rem] pb-[1.25rem] mb-[1rem]"
        data-test-gisement={`${operation.primes.gisement}`}
      >
        <div className="flex justify-between pb-[1.25rem] items-center ">
          <div className="flex lg:space-x-[2rem] space-x-[1rem] items-center">
            <p>Opération {index + 1}</p>
            <EditSquareIcon
              width="1.25rem"
              height="1.25rem"
              fill={textColor}
              className="cursor-pointer mb-[.375rem]"
              onClick={editOperation}
            />
          </div>
          {choiceSelected ? (
            <Checkbox
              fill={
                isThemeDark
                  ? white
                  : choiceSelected && !isThemeDark
                  ? colorTextButton
                  : !choiceSelected && !isThemeDark
                  ? colorTextLightMode
                  : ''
              }
              className="w-[1.5rem] h-[1.5rem]"
            />
          ) : (
            <NotChecked
              fill={
                isThemeDark
                  ? white
                  : choiceSelected && !isThemeDark
                  ? colorTextButton
                  : !choiceSelected && !isThemeDark
                  ? colorTextLightMode
                  : ''
              }
              className="w-[1.5rem] h-[1.5rem]"
            />
          )}
        </div>
        <p className="pb-[.875rem]">{operation.name}</p>
        <div
          style={{
            color: isThemeDark
              ? white
              : choiceSelected && !isThemeDark
              ? colorTextButton
              : !choiceSelected && !isThemeDark
              ? colorTextLightMode
              : '',
          }}
          className="flex justify-between pb-[.5rem]"
        >
          <p>Coût matériel et pose</p>
          <p>{`${numFormatSpace(operation.totalCost)} € TTC`}</p>
        </div>
        <div className="flex justify-between pb-[.5rem]">
          <p>Prime MPR</p>
          <p
            className={
              mprIsSelect && operation.primes.mpr !== 0 ? '' : 'line-through'
            }
          >{`${numFormatSpace(operation.primes.mpr)} € TTC`}</p>
        </div>
        <div className="flex justify-between pb-[.5rem]">
          <p>Prime CEE {ceeBenef()}</p>
          <p className={ceeIsSelect ? '' : 'line-through'}>{cee}</p>
        </div>
        <div className="flex justify-between pt-[.5rem] border-t dark:border-t-white border-t-borderLightMode2">
          <p>Reste à charge</p>
          <p>{remains()}</p>
        </div>
      </div>
      {choiceSelected &&
        totalBonus() > 0 &&
        operation.primes.bonus &&
        Object.keys(operation.primes.bonus).length > 0 && (
          <BonusCard bonusObj={operation.primes.bonus} />
        )}
    </>
  );
}

export default OperationCardSummary;
